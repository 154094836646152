function enableNavbarHighlights() {
    let navbar = document.getElementById('navbar')
    let subNav = document.getElementById('subNavBar')
    
    if (!navbar) {
        return
    }

    let menuItems = navbar.querySelectorAll('a')
    // let subMenuItems = subNav.querySelectorAll('a')
    
    let currentPage = window.location.href
    
    function handleOf(href) {
        let handle = href.split("://")[1].split("/")[1]
        return handle
    }

    for (item of menuItems) {
        if (handleOf(item.href) == handleOf(currentPage)) {
            item.classList.add("active", "bg-secondary", "bg-opacity-25")
        }
    }
}

enableNavbarHighlights()